import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKamil as author } from 'data/authors';
import img from 'img/blog/new-filters-cover.png';

import img1 from 'img/blog/new-session-filters.png';
import img2 from 'img/blog/duplicate-filters.png';
import img3 from 'img/blog/autocomplete-filters.png';

const Content = () => {
  return (
    <Post>
      <p>
        Filters are the core features of LiveSession. To make your work easier, we’ve just
        introduced a range of new features:
      </p>
      <h2 id="new-filter-pages-count">New filter: pages count</h2>
      <p>
        <img src={img1} alt="New session filters" title="New filter - pages count" />
      </p>
      <p>
        By your request, you can now filter recordings by <strong>pages count</strong>. For
        instance, you can only choose to see sessions where the user has visited more than three
        pages on your website.
      </p>
      <h2 id="update-duplicate-and-disable-filters">Update: Duplicate and disable filters</h2>
      <p>
        <img src={img2} alt="Duplicate filters" title="Duplicate filter with one click" />
      </p>
      <p>
        Pages count is not the only request we’ve answered! Also by popular demand, you can now{' '}
        <strong>duplicate the filter</strong> in just one click. Adding multiple variables is now
        much faster.
      </p>
      <p>
        What’s more, you can also use the <strong>toggle</strong> to temporarily disable the filter
        and compare results. You don’t have to delete it completely anymore.
      </p>
      <h2 id="update-autocomplete-for-event-filters">Update: Autocomplete for event filters</h2>
      <p>
        <img src={img3} alt="Autocomplete filters" title="Autocomplete for event filters" />
      </p>
      <p>What’s more, using event filters is now much easier!</p>
      <p>
        LiveSession will now suggest relevant values. We autocomplete the filters with top results,
        like in the screenshot above.
      </p>
      <p>
        If you have any other ideas for improvement, we’d love to hear from you! Please get in touch
        via live chat or send us an email to{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Filters update',
  url: '/blog/filtering-updates/',
  description: `Filtering session recordings in LiveSession just got easier. See what’s new in filters!`,
  author,
  img,
  imgSocial: img,
  date: '2020-07-18',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
